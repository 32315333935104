class ZIDXSearchEmbedView implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxSearchEmbedViewContainer";
    }

    getMatchURL() {
        return "/search-embed/";
    }

    render() {
        // console.log("site home");
        window.zidxBase.init(false, true, false, false)
    }
}